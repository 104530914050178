import React, {useEffect, useState} from 'react';
import topSvg from '../../assets/img/announcement/top.svg';
import botSvg from '../../assets/img/announcement/bottom.svg';
import '../../assets/css/global.scss';
import {client} from "../../services/api.service";
import HonorWidget from "./HonourWidget";
import Loader from "../../components/Loader/Loader";
import moment from "moment/moment";

export interface IHonoursPicture {
    contentType: string;
    enableRangeProcessing: boolean;
    entityTag: null;
    fileContents: string;
    fileDownloadName: string;
    lastModified: null;
}

export interface IHonoursData {
    phaseId: string;
    phaseNumber: string;
    phaseWinner: string;
    phase2ndPlace: string;
    winnerPicture: IHonoursPicture;
    from: string,
    to: string,
}


const Honours: React.FC = () => {

    const [honours, setHonours] = useState<IHonoursData[]>([]);
    const [activeWidgetId, setActiveWidgetId] = useState<number>(0);
    const tryGetHonors = async () => {
        try {
            const honours = await client.get('/api/mss-web/honours-init');
            setHonours(honours.data.honors);
        } catch (e: unknown) {
            if (e instanceof Error) {
                console.log('tryGetHonors:', e.message);
            }
        }
    }


    useEffect(() => {
        tryGetHonors();
    }, [])

    const isShowDropDownHandler = (id: number): void => {
        if (id === activeWidgetId) {
            return setActiveWidgetId(0);
        }
        setActiveWidgetId(id);
    }

    return (
        <>
            <div className="headerSpace"/>

            <div className="newsTitle">
                <div className="wrap">
                    <h1>Honours Board</h1>
                </div>
            </div>

            <div className="honours">
                <div className="wrap">
                    <div
                        className={`row ${(!!(honours.length % 2) && honours.length !== 1) ?
                            'd-flex justify-content-start' : 'd-flex justify-content-center'}`}>
                        {
                            honours.length ?
                                honours.map((honor: IHonoursData) => {
                                    return (
                                        <HonorWidget
                                            key={honor.phaseId}
                                            data={honor}
                                            id={+honor.phaseId}
                                            visibility={
                                                activeWidgetId === +honor.phaseId ? "dropdown-players-show" : "dropdown-players-hidden"
                                            }
                                            visibilityHandler={isShowDropDownHandler}
                                        />
                                    )
                                })
                                :
                                <Loader/>
                        }
                    </div>
                </div>
            </div>


            <div className="seriesSchedule">
                <img src={topSvg} className="top" alt={'topSvg'}/>
                <img src={botSvg} className="bottom hideMobile" alt={"botSvg"}/>
                <div className="wrap">
                    <div className="sub">{moment().format("YYYY")} Planner</div>
                    <h3>CURRENT SERIES<span className="hideMobile"> SCHEDULE </span></h3>
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-3 justify-content-center">
                            <div className="scheduleItem">
                                {honours[honours.length - 1] && moment(honours[honours.length - 1].from)?.format("Do MMM")}
                                <span className={"date-separator"}>  - </span>
                                {honours[honours.length - 1] && moment(honours[honours.length - 1].to)?.format("Do MMM")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="returnStatus"/>
            <script
                src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                crossOrigin="anonymous"
            />
            <script
                src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"
                integrity="sha512-Eak/29OTpb36LLo2r47IpVzPBLXnAMPAVypbSZiZ4Qkf8p/7S/XRG5xp7OKWPPYfJT6metI+IORkR5G8F900+g=="
                crossOrigin="anonymous" referrerPolicy="no-referrer"
            />
            <script
                type="text/javascript"
                src="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"
            />
        </>
    )
}

export default Honours;

import React, {useEffect, useMemo, useState} from 'react';
import topBG from '../../assets/img/announcement/top.svg'
import bottomBG from '../../assets/img/announcement/bottom.svg'
import {useDispatch} from "react-redux";
import {Dropdown, DropdownProps} from "semantic-ui-react";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useHistory} from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {ActionsCreator} from "../../redux/actions/actionCreator";
import {generateTable} from "./helpers/generateTable";
import {generateFixture} from "./helpers/generateFixture";
import {useFilterFixturesTables} from "./helpers/useFilterFixturesTables";
import './style.scss';
import './style-media.scss';

export interface IFixtureLabel {
    label: string;
    labelClass: string;
}

const Results = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const fixturesTables = useTypedSelector(state => state.fixtures);
    const {
        preSelectedInfo: {week, series, group},
        fixtures,
        filters,
        winnerTitle,
        winnerName,
        isLoading,
        table
    } = useTypedSelector(state => state.fixtures);

    const [currentTableGroup, setCurrentTableGroup] = useState(1);
    // const {
    //     currentFinalTable,
    //     currentTable,
    // } = useFilterFixturesTables(fixturesTables, group, currentTableGroup)

    useEffect(() => {
        dispatch(ActionsCreator.getResults());
    }, [])

    const phaseOptions = useMemo(() => {
        return filters.series.map(phase => ({
            key: phase.id,
            text: phase.name,
            value: phase.id
        })).reverse()
    }, [filters.series])

    const weekOptions = useMemo(() => {
        return filters.weeks.map(week => ({
            key: week.id,
            text: week.name,
            value: week.id
        }))
    }, [filters.weeks])


    const setCurrentGroup = (id: number) => {
        dispatch(ActionsCreator.setSelectedGroup(id))
        const query = `?group=${id}&week=${week}`
        dispatch(ActionsCreator.getResultsByFilter(query))
    };

    const moveToMatchStatistic = (fixtureId: number) => history.push(`match/statistics/${fixtureId}`);

    const onChangePhase = (e: React.SyntheticEvent, {value}: DropdownProps) => {
        dispatch(ActionsCreator.setSelectedSeries(value as number))
        const query = `?series=${value}`
        dispatch(ActionsCreator.getResultsByFilter(query))
    }
    const onChangeWeek = (e: React.SyntheticEvent, {value}: DropdownProps) => {
        dispatch(ActionsCreator.setSelectedWeek(value as number))
        const query = `?week=${value}`
        dispatch(ActionsCreator.getResultsByFilter(query))
    }

    return (

        <div>
            <div className="headerSpace"/>
            <div className="newsTitle">
                <div className="wrap">
                    <h1>Results & Fixtures</h1>
                </div>
            </div>
            {!isLoading && <div className="matchStats results ">
                <div className="wrap">
                    <div className="row meta">
                        {
                            <div className="col-12 col-xl-6 text-center group-tabs hideDesktop">
                                {filters.groups.map(item => {
                                    return (
                                        <a
                                            onClick={() => setCurrentGroup(item.id)}
                                            className={group === item.id ? "tab active-group" : "tab"}>
                                            {item.name}
                                        </a>
                                    )
                                })}
                            </div>
                        }
                        <div className="col-3 season-tabs hideMobile">
                            {
                                <Dropdown
                                    className="season-dropdown season-dropdown-phase"
                                    options={phaseOptions}
                                    onChange={onChangePhase}
                                    value={series}
                                />
                            }
                        </div>

                        <div className="col-3 col-sm-12 col-12 season-tabs hideDesktop">
                            <div className="mobile-seasons-tabs">
                                <div className="mobile-season-tabs">
                                    {
                                        <Dropdown
                                            className="season-dropdown season-dropdown-phase"
                                            options={phaseOptions}
                                            onChange={onChangePhase}
                                            value={series}
                                        />
                                    }
                                </div>
                                <div className="mobile-weeks-tabs">
                                    {
                                        <Dropdown
                                            className="season-dropdown"
                                            options={weekOptions}
                                            value={week}
                                            onChange={onChangeWeek}
                                        />
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="col-6 text-center group-tabs hideMobile">
                            {filters.groups.map(item => {
                                return (
                                    <a
                                        onClick={() => setCurrentGroup(item.id)}
                                        className={group === item.id ? "tab active-group" : "tab"}>
                                        {item.name}
                                    </a>
                                )
                            })}
                        </div>
                        <div className="col-3 right week-tabs hideMobile">
                            <Dropdown
                                className="season-dropdown"
                                options={weekOptions}
                                value={week}
                                onChange={onChangeWeek}
                            />
                        </div>
                    </div>
                </div>
            </div>}


            <div className="fixtureBlock">
                {
                    isLoading ?
                        <Loader/>
                        :
                        <div className="wrap">
                            <div className="row">
                                <div className="col-12 col-xl-12">
                                    <div className="row">
                                        {fixtures?.map(fixture => {
                                            return (
                                                generateFixture(fixture, group, moveToMatchStatistic)
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                <div className="winner">
                <span
                    className="winnerTitle m-0">
                    {winnerTitle}
                </span>
                    <span
                        className="winnerName m-0">{winnerName}</span>
                </div>
            }

            {fixturesTables?.isLoading === false &&
                <div className="announcement tableItem hideMobile" style={{marginBottom: 0}}>
                    <img src={topBG} alt="top" className="top"/>
                    <img src={bottomBG} alt="bot" className="bottom"/>
                    {
                        <div className="wrap">
                            <div className="sub">{`2023`}</div>
                            <h3>How the table looks</h3>

                            {group === 4 &&
                                <div className="matchStats results hideMobile">
                                    <div className="wrap">
                                        <div className="row meta">

                                            {table.length > 0 &&
                                                <div className="col-12 col-xl-12 text-center">
                                                    <a
                                                        onClick={() => setCurrentTableGroup(1)}
                                                        className={currentTableGroup === 1 ? "tab active-group" : "tab"}>
                                                        Group 1
                                                    </a>

                                                    <a
                                                        onClick={() => setCurrentTableGroup(2)}
                                                        className={currentTableGroup === 2 ? "tab active-group" : "tab"}>
                                                        Group 2
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="currentTable hideMobile">
                                {group !== 4 &&
                                    table?.map((item, index) => {
                                        return (
                                            generateTable(item, index)
                                        )
                                    })}
                                {group === 4 &&
                                    table?.map((item, index) => {
                                        return (
                                            generateTable(item, index)
                                        )
                                    })}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default Results;
